import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

import { Box, Button, Flex, Heading, Spacer, Text } from "@chakra-ui/react";

import { ActionType, CTA, ManageScreenProps } from "../types";

import BlockRenderer from "../components/BlockRenderer";
import Breadcrumbs from "../components/Breadcrumbs";
import NavHeader from "../components/NavHeader";
import ResourceList from "../components/ResourceList";
import ResponsiveContentWrapper from "../components/ResponsiveContentWrapper";
import SubTitle from "../components/SubTitle";

import { MIN_CARD_INNER_HEIGHT } from "../../utils/constants";
import PlusCircle from "../icons/PlusCircle";
import { indefiniteArticle } from "./screenUtils";

const ManageScreen: React.FC<ManageScreenProps> = (
  props: ManageScreenProps,
) => {
  const { screen, onSubmit, onBack, supportEnabled } = props;

  // TODO(marcia): What is this _isLoading/setIsLoading for?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const clearLoaders = () => {
    setIsDisabled(false);
    setLoadingButton("");
  };

  useEffect(() => {
    window && window.scrollTo(0, 0);
    ref?.current?.scrollIntoView();
  }, [screen]);

  const handleNext = () => {
    setIsDisabled(true);
    timer.current = setTimeout(() => {
      setLoadingButton(CTA.CONTINUE);
    }, 250);
    onSubmit({ type: ActionType.CONTINUE });
  };

  const handleBack = () => {
    setIsDisabled(true);
    timer.current = setTimeout(() => {
      setLoadingButton(CTA.BACK);
    }, 250);
    onBack();
  };

  const addResource = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setIsDisabled && setIsDisabled(true);
      setLoadingButton(CTA.MANAGE_ADD);
      // If we don't get response within 250ms, we turn loaders on
      setTimeout(() => {
        setIsLoading(true);
      }, 250);
      onSubmit({ type: ActionType.ADD });
    }
  };

  const addButtonText = `Add ${indefiniteArticle(screen.labels.addAnother)} ${
    screen.labels.addAnother
  }`;

  // For backwards + forwards compatibility -- if the continueButtonEnabled flag
  // does not exist at all, show the continue button. If the continue button is set
  // to false, we will hide the button
  // When the BE solidifies with low chance of revert, then this can be updated to
  // the simpler:
  //   continueButtonEnabled = screen.continueButtonEnabled;
  const continueButtonEnabled =
    screen.continueButtonEnabled === undefined || screen.continueButtonEnabled;

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && continueButtonEnabled) {
      e.preventDefault();
      handleNext();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvent);

    return () => {
      window.removeEventListener("keydown", handleKeyboardEvent);
    };
  });

  return (
    <Box w="full" flexDirection="column" zIndex={1}>
      <NavHeader
        onBack={onBack}
        onSubmit={onSubmit}
        bannerItems={screen.bannerItems}
        renderBack={screen.renderBack}
        tempScreenId={screen.id}
        supportEnabled={supportEnabled}
        showDesktopTest={screen.desktopIcon}
        screen={screen}
      />
      <ResponsiveContentWrapper scrollTargetRef={ref} size="tablet">
        <Flex
          minHeight={{ base: "appInnerHeight", md: MIN_CARD_INNER_HEIGHT }}
          direction="column"
          justifyContent="space-between"
          gridRowGap={{ base: 4, md: 12 }}
        >
          <Flex direction="column" gap={{ base: 8, md: 12 }}>
            {/* this wrapper is needed for the button to stick to the bottom on mobile correctly */}

            <Flex flexDirection="column" gap={4}>
              {/* this is the header wrapper */}
              <Breadcrumbs screen={screen} onSubmit={onSubmit} />
              {/* TODO (chris) will remove || screen.id once all screens have titles */}
              <Heading color="text.primary" my={0}>
                {screen.title || screen.id}
              </Heading>
              {screen.subTitle && (
                <SubTitle text={screen.subTitle} color="text.primary" />
              )}
              {screen.optionalBlocks && (
                <BlockRenderer
                  blocks={screen.optionalBlocks}
                  onSubmit={onSubmit}
                  screen={screen}
                />
              )}
              <Box
                display={{ base: "none", md: "flex" }}
                marginTop={4}
                height={0}
                width="full"
                borderTop="1px dashed"
                borderTopColor="border.medium"
              />
            </Flex>

            <ResourceList
              screen={screen}
              deleteButtonEnabled={screen.deleteButtonEnabled}
              onSubmit={onSubmit}
              clearLoaders={clearLoaders}
              currentTimer={timer.current}
              navigationDisabled={isDisabled}
              navigationDisableSetter={setIsDisabled}
            />

            {screen.addButtonEnabled && (
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Button
                  width="100%"
                  variant="outline"
                  rounded="button"
                  onClick={!isDisabled ? addResource : undefined}
                  height={14}
                  maxW="300px"
                  isLoading={loadingButton === CTA.MANAGE_ADD}
                  isDisabled={isDisabled}
                >
                  <Flex p="3" alignItems="center">
                    <PlusCircle />
                    {/* TODO(marcia): Audit all manage screens and ensure we
                      are passing the correctly capitalized word to FE. Then
                      remove the following textTransform.
                      See https://linear.app/column-tax/issue/COL-2134/audit-all-manage-screens-for-correct-capitalization-of-managed-item
                      */}
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      textAlign="left"
                      marginLeft="3"
                    >
                      {addButtonText}
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
          </Flex>
          <Box>
            {!continueButtonEnabled && (
              <Text mb={4} fontWeight="semibold" textAlign="center">
                Complete each section to continue
              </Text>
            )}
            <Flex grow={1} flexDirection="column">
              <Spacer display={{ base: "initial", md: "none" }} />
              <Box
                display={{ base: "none", md: "flex" }}
                paddingTop={12}
                width="full"
                borderTop="1px dashed"
                borderTopColor="border.medium"
              />
              <Flex w="full" gap={4} justifyContent="center">
                {screen.renderBack && screen.backCtaText && (
                  <Button
                    mr={6}
                    px={8}
                    form={screen.id}
                    variant="gray"
                    onClick={handleBack}
                    isLoading={loadingButton === CTA.BACK}
                    isDisabled={isDisabled}
                    loadingText={screen.backCtaText}
                    aria-label="Back CTA"
                    maxW="160px"
                  >
                    {screen.backCtaText}
                  </Button>
                )}
                <Button
                  width="full"
                  form={screen.id}
                  type="submit"
                  size={"md"}
                  onClick={handleNext}
                  textTransform="capitalize"
                  variant="primary"
                  isLoading={loadingButton === CTA.CONTINUE}
                  isDisabled={!continueButtonEnabled || isDisabled}
                  loadingText={screen.labels.continue}
                  maxW="300px"
                >
                  {screen.labels.continue}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </ResponsiveContentWrapper>
    </Box>
  );
};

export default ManageScreen;
