import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { CheckmarkItem } from "../types";

import theme from "../../theme";
import CheckmarkListItem from "./CheckmarkListItem";

type Props = {
  children: CheckmarkItem[];
  mx?: BoxProps["mx"];
  maxWidth?: string;
  flexDir?: "row" | "column";
};

const listTheme = theme.components.CheckmarkListItem.sizes.md;

const CheckmarkList: React.FC<Props> = ({
  children,
  mx = "auto",
  maxWidth = listTheme.maxWidth,
  flexDir = "row",
}: Props) => {
  return (
    <Box
      mx={mx}
      maxWidth={maxWidth}
      display="flex"
      flexDirection="column"
      gridRowGap={listTheme.gap}
    >
      {children.map((checkMarkItem: CheckmarkItem, idx: number) => {
        return (
          <CheckmarkListItem
            key={idx}
            index={idx}
            label={checkMarkItem.label}
            subLabel={checkMarkItem.subLabel}
            iconType={checkMarkItem.iconType}
            flexDir={flexDir}
          />
        );
      })}
    </Box>
  );
};

export default CheckmarkList;
