import { ZendeskAPI } from "react-zendesk";

import { NotAuthorizedError } from "./shared/api";

import { getZendeskToken } from "./diy/api";
import { Screen, UserDetails } from "./diy/types";
import { redirectToExitPage } from "./utils/api";

// This is the production Zendesk key which helps us open up the correct Zendesk
// widget, and you will find instructions for it here:
// https://columntax.zendesk.com/admin/channels/classic/web-widget/9501218518541/edit/installation
export const ZENDESK_KEY = "0b5cf703-0f2d-437e-a417-d54aa2dccd44";

// This is the sandbox Zendesk key, see
// https://columntax1712869465.zendesk.com/admin/channels/messaging_and_social/channels_list/web_widget/25826213847565/edit
export const ZENDESK_SANDBOX_KEY = "139c9516-9b20-4084-be2e-ec87ca077616";

export const ZENDESK_BASE_SETTINGS = {
  webWidget: {
    launcher: {
      mobile: {
        labelVisible: true,
      },
      talkLabel: {
        "*": "Help",
      },
    },
    contactOptions: {
      enabled: true,
      contactButton: { "*": "Get in touch" },
    },
  },
  helpCenter: {
    originalArticleButton: false,
  },
  chat: {},
  talk: {},
  contactForm: {},
  authenticate: {
    chat: {
      jwtFn: (callback: (jwt: string) => Promise<void>) => {
        getZendeskToken()
          .then(function (response: { token: string }) {
            callback(response.token);
          })
          .catch((error) => {
            if (error instanceof NotAuthorizedError) {
              redirectToExitPage();
            }
          });
      },
    },
  },
};

export const updateSupportContext = (
  screenId: string,
  useZendeskProductionClassic: boolean,
) => {
  if (!screenId) {
    return;
  }

  if (!useZendeskProductionClassic) {
    return;
  }

  const splitScreenId = screenId.split("::");
  const screenIdSuffix = splitScreenId[splitScreenId.length - 1];
  ZendeskAPI("webWidget", "helpCenter:setSuggestions", {
    labels: [screenIdSuffix],
  });
};

export const openZendesk = async (useZendeskProductionClassic: boolean) => {
  if (useZendeskProductionClassic) {
    ZendeskAPI("webWidget", "chat:reauthenticate");
    ZendeskAPI("webWidget", "open");
    ZendeskAPI("webWidget", "show");
  } else {
    // Authenticate the user before opening messenger
    const response = await getZendeskToken();
    ZendeskAPI("messenger", "loginUser", function (callback: any) {
      callback(response.token);
      ZendeskAPI("messenger", "open");
    });
  }
};

export const showLauncher = (useZendeskProductionClassic: boolean) => {
  if (useZendeskProductionClassic) {
    ZendeskAPI("webWidget", "show");
  }
};

export const hideLauncher = (useZendeskProductionClassic: boolean) => {
  if (useZendeskProductionClassic) {
    ZendeskAPI("webWidget", "hide");
  }
};

export const zendeskOnLoad = (
  screen: Screen,
  userDetails: UserDetails,
  useZendeskProductionClassic: boolean,
) => {
  // It does not seem like we need to call this for the new messaging widget
  if (!useZendeskProductionClassic) {
    return;
  }

  if (userDetails.name) {
    ZendeskAPI("webWidget", "prefill", {
      email: {
        value: userDetails.email,
        readOnly: true,
      },
      name: {
        value: userDetails.name,
        readOnly: true,
      },
    });
  } else {
    ZendeskAPI("webWidget", "prefill", {
      email: {
        value: userDetails.email,
        readOnly: true,
      },
    });
  }

  if (window.zE) {
    window.zE("webWidget", "updateSettings", {
      webWidget: { zIndex: 2 },
    });
  }

  // Make sure context is reloaded on load
  updateSupportContext(screen.id, useZendeskProductionClassic);

  // Hide the launcher if showLauncher
  // is set to false
  if (!screen.showLauncher) {
    hideLauncher(useZendeskProductionClassic);
  }
};
