import React, { useEffect } from "react";

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { Block, BlockType, ButtonVariant, Screen, ScreenId } from "../../types";

import BlockRenderer from "../BlockRenderer";

interface Props {
  contents: Block[];
  screen: Screen;
  onSubmit: (screenId: ScreenId) => void;
  dismissModalText: string;
  dismissModalTextVariant: ButtonVariant;
}

const ModalBlock: React.FC<Props> = ({
  contents,
  onSubmit,
  screen,
  dismissModalText,
  dismissModalTextVariant,
}) => {
  // TODO(marcia): Add mixpanel events here?
  // TODO(marcia): This modal and spacing are all copied from our CloseButton's modal.
  // I didn't want to attempt a modal/components refactor the last few days of the season, so
  // I opted to copy the contents here on this as of yet unreleased feature.
  // Unify some time after season deadline when things feel less risky.
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  useEffect(() => {
    onOpenModal();
  }, []);

  let nonCtaBlocks: Block[] | [] = [];
  let ctaBlocks: Block[] | [] = [];
  contents.map((block: Block) => {
    if (block.type === BlockType.W2_UPLOAD) {
      ctaBlocks = [...ctaBlocks, block];
    } else {
      nonCtaBlocks = [...nonCtaBlocks, block];
    }
  });

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onCloseModal}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        height="auto"
        maxHeight="full"
        margin="0"
        borderRadius="0"
        bottom="0px"
        borderTopRadius="card"
        borderBottomRadius={{ base: 0, md: "card" }}
        // Use safe-area-inset-top to account for iphone notch
        mt="max(20px, env(safe-area-inset-top))"
        containerProps={{
          height: "full",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: { base: "flex-end", md: "center" },
          borderTopRadius: "card",
        }}
      >
        <ModalCloseButton size="lg" />
        <ModalBody
          px={{ base: 8, md: 0 }}
          pt={{ base: 14, md: 0 }}
          pb={{ base: 12, md: 0 }}
          display="flex"
          flexDirection="column"
        >
          <Flex direction="column" height="auto" gap="8">
            <Flex flexDirection="column" gap={4}>
              <BlockRenderer
                blocks={nonCtaBlocks}
                onSubmit={onSubmit}
                screen={screen}
              />
            </Flex>
            <Flex
              // A button should take up half container width minus half of row grid gap
              minW={{ md: "calc(50% - 6px)" }}
              flexDirection={{ base: "column", md: "row" }}
              gap="4"
              flexGrow={1}
              justifyContent="center"
            >
              <BlockRenderer
                blocks={ctaBlocks}
                onSubmit={onSubmit}
                screen={screen}
              />
              <Button
                width="full"
                mt={undefined}
                onClick={onCloseModal}
                variant={dismissModalTextVariant}
                maxW={{ md: "300px" }}
              >
                {dismissModalText}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalBlock;
