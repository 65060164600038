import React from "react";

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { TableRow } from "../types";

import { dollarsToUsd } from "../helpers/CurrencyFormatter";

const TableBlock = ({
  header,
  rows,
  variant,
  formatAsDollar,
}: {
  header: string;
  rows: TableRow[];
  variant: string;
  formatAsDollar: boolean;
}) => {
  const tableRows = rows.map((row, idx) => {
    return (
      <Tr key={idx}>
        <Td>{row.label}</Td>
        <Td>
          {formatAsDollar ? dollarsToUsd(row.value as number) : row.value}
        </Td>
      </Tr>
    );
  });

  return (
    <TableContainer>
      <Table variant={variant}>
        {header && (
          <Thead>
            <Tr>
              <Th colSpan={2}>{header}</Th>
            </Tr>
          </Thead>
        )}
        <Tbody>{tableRows}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableBlock;
