import { StyleFunctionProps, extendTheme } from "@chakra-ui/react";

import { catalogTheme } from "../diy/internal/catalog/theme";

const theme = extendTheme(
  {
    styles: {
      global: {
        html: {
          height: "100%",
          margin: "0",
          WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
        },
        body: {
          height: "100%",
          margin: "0",
          color: "text.primary",
          backgroundColor: "background.light",
        },
        ".screenbuilderLink": {
          color: "#0047FF",
        },
      },
    },

    // Breakpoint values translated to `em` so that Chakra's
    // `useBreakpoint` and `useBreakpointValue` hooks actually work
    breakpoints: {
      base: "0em",
      xs: "22.5em",
      sm: "25.875em",
      md: "35.5em",
      navmenu: "64em",
      lg: "87.5em",
      xl: "100em",
      max: "120em",
    },

    sizes: {
      modal: "22.5rem",
      appHeight: "100%",
      appInnerHeight: "100%",
      sm: "26rem",
      md: "568px",
      tablet: "848px",
      lg: "1400px",
    },

    fonts: {
      body: "Proxima Nova",
      heading: "Proxima Nova",
    },

    fontSizes: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      body: "16px",
      // TODO(marcia): Instead of using h4 like this everywhere, could we
      // use the `h4` html tag and style that accordingly?
      h4: "18px",
    },

    lineHeights: {
      sm: "20px",
      md: "22px",
      body: "22px",
      h4: "24px",
    },

    fontWeights: {
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },

    radii: {
      card: "16px",
      button: "50px",
      expertTipPhoto: "100px",
    },

    shadows: {
      card: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
    },

    spacing: {
      15: "60px",
    },

    components: {
      Menu: {
        baseStyle: {},
        variants: {
          resource: {
            list: {
              borderRadius: "card",
              border: "none",
              // Use 0 padding here -- we want the menu items to be flush
              // against the list. This way the entire span of the menu item is
              // a valid click target.
              p: 0,
              boxShadow: "card",
              width: "auto",
              minWidth: "auto",
            },
            item: {
              // Use borderRadius "card" so that this item's corners do not poke
              // out of the MenuList.
              borderRadius: "card",
              px: 8,
              py: 4,
              height: "auto",
              width: "auto",
              minWidth: "auto",
              _active: {
                background: "white",
              },
              _hover: {
                background: "white",
              },
              _focus: {
                background: "white",
              },
            },
          },
        },
      },

      Text: {
        sizes: {
          // See the Typography section of the Column Tax UI Kit at o/ui-kit for details
          xs: {
            // Not in UI kit so far
            fontSize: "12px",
            lineHeight: "20px",
          },
          sm: {
            // Caption text
            fontSize: "14px",
            lineHeight: "20px",
          },
          md: {
            // Default body text
            fontSize: "16px",
            lineHeight: "22px",
          },
          lg: {
            // Question text
            fontSize: "18px",
            lineHeight: "24px",
          },
          xl: {
            // Not in UI kit yet, but used for SummaryHeaderBlock fed/state totals.
            fontSize: "20px",
            lineHeight: "24px",
          },
        },
        variants: {
          modalBodyText: {
            marginY: 5,
          },
        },
      },
      Heading: {
        sizes: {
          // H3
          lg: {
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: "bold",
          },
          // Default Heading / screen title
          xl: {
            fontSize: "28px",
            lineHeight: "36px",
          },
          headline: {
            fontSize: "32px",
            lineHeight: "40px",
            fontWeight: 800,
          },
          caption: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
          },
          h4: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 500,
          },
          h4_bold: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 700,
          },
        },
        variants: {
          modalBodyHeader: {
            paddingRight: 8,
          },
        },
      },
      Table: {
        sizes: {
          md: {
            th: {
              fontSize: "md",
              lineHeight: "22px",
            },
          },
        },
        baseStyle: {
          th: {
            fontFamily: "heading",
            background: "background.dark",
            fontWeight: "semibold",
            letterSpacing: "normal",
            textTransform: "none",
            padding: "16px",
            "&:first-of-type": {
              borderTopLeftRadius: "md",
            },
            "&:last-child": {
              borderTopRightRadius: "md",
            },
          },
          table: {
            borderWidth: "1px",
            borderColor: "border.medium",
            borderRadius: "md",
            borderSpacing: "0",
            borderCollapse: "separate",
          },
          td: {
            paddingTop: 4,
            paddingBottom: 4,
            borderBottom: "none",
            // for single column tables, we don't want to have it right-align, so we also explicitly set the first-of-type
            // to left-align (so that the last-child CSS doesn't apply)
            // TODO: we could break out the styling here so baseStyles do less
            "&:first-of-type": {
              textAlign: "left",
              paddingLeft: 4,
            },
            "&:last-of-type": {
              textAlign: "right",
            },
          },
          tr: {
            "&:last-of-type": {
              td: {
                borderBottom: "1px",
                borderColor: "border.medium",
              },
            },
          },
        },
        variants: {
          // To define a new variant, add the name of the variant as a new key in this object (e.g. "summary" below)
          // Note that all variants inherit from the "baseStyle" variant defined by Chakra UI here:
          // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/table.ts
          internal: {
            table: {
              borderWidth: "none",
              borderRadius: "0px",
              th: {
                padding: 1,
              },
              td: {
                padding: 1,
                "&:first-of-type": {
                  padding: 1,
                },
              },
            },
            th: {
              textTransform: "uppercase",
              letterSpacing: "wider",
              textAlign: "start",
            },
            td: {
              "&:last-child": {
                textAlign: "start",
              },
            },
          },

          summary: {
            // Used for summarizing the amounts of a return (for federal or state)
            td: {
              "&:first-of-type": {
                paddingLeft: 4,
                paddingRight: 1,
                paddingInlineRight: 1,
              },
              "&:last-of-type": {
                letterSpacing: "-0.5px",
                paddingRight: 4,
                paddingLeft: 1,
                paddingInlineLeft: 1,
              },
              fontSize: {
                base: "xs",
                xs: "sm",
                sm: "md",
              },
            },
            tr: {
              "&:first-of-type": {
                td: {
                  paddingLeft: "16px",
                  fontWeight: "semibold",
                },
                th: {
                  paddingLeft: "16px",
                },
              },
            },
          },

          infoSummary: {
            td: {
              color: "text.primary",
              verticalAlign: "top",
              paddingLeft: "16px",
              paddingRight: "16px",
              "&:last-of-type": {
                fontWeight: "semibold",
              },
              fontSize: {
                base: "xs",
                xs: "sm",
                sm: "md",
              },
            },
            tr: {
              td: {
                paddingLeft: "16px",
                paddingRight: "16px",
              },
              th: {
                paddingLeft: "16px",
                paddingRight: "16px",
              },
            },
          },
          status: {
            // Used for summarizing the return (for federal or state)
            borderRadius: "12px",
            table: {
              fontSize: {
                base: "xs",
                xs: "sm",
                sm: "md",
              },
            },
            th: {
              fontSize: {
                base: "xs",
                xs: "sm",
                sm: "md",
              },
              paddingLeft: {
                base: "16px",
              },
            },
            tbody: {
              "&:last-child": {
                td: {
                  border: "none",
                },
              },
            },
            td: {
              fontSize: {
                base: "xs",
                xs: "sm",
                sm: "md",
              },
              "&:first-child": {
                paddingRight: {
                  base: "16px",
                  sm: "2px",
                },
              },
              "&:last-child": {
                paddingLeft: {
                  base: "16px",
                  sm: "2px",
                },
                fontWeight: "semibold",
              },
            },
          },
          banking: {
            // Used for displaying bank account information for deposits
            td: {
              "&:last-child": {
                fontWeight: "semibold",
              },
            },
          },
          eligibility_explanation: {
            // Used for displaying (in)eligiblity reasons for credits
            td: {
              lineHeight: "lg",
              padding: 4,
              "&:last-of-type": {
                textAlign: "left",
              },
            },
            th: {
              fontWeight: "semibold",
            },
          },
          credit_summary: {
            // Used for displaying credit summary amounts
            table: {
              borderColor: "border.medium",
              rounded: "xl",
            },
            td: {
              borderBottom: "white",
              "&:last-of-type": {
                fontWeight: "bold",
              },
            },
            tr: {
              "&:first-of-type": {
                td: {
                  borderTop: "0px",
                },
              },

              "&:last-of-type": {
                td: {
                  borderBottom: "0px",
                },
              },
            },
          },
        },
      },
      Button: {
        baseStyle: {
          borderRadius: "button",
          _focus: {
            boxShadow: "none",
          },
        },
        sizes: {
          sm: {
            fontSize: "md",
            lineHeight: "md",
            fontWeight: "semibold",
            px: 8,
            h: 12,
          },
          md: {
            fontSize: "h4",
            lineHeight: "h4",
            fontWeight: "bold",
            px: 8,
            py: 6,
            h: 14,
          },
        },
        variants: {
          link: {
            fontWeight: "semibold",
            fontSize: "body",
          },

          outline: {
            fontWeight: "semibold",
            background: "white",
            color: "brand.medium",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "brand.medium",

            _disabled: {
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              color: "brand.medium",
              opacity: 0.5,
            },

            _active: {
              background: "background.light",
              border: "none",
            },

            // Hover won't come into play on mobile, but
            // chakra's default hover is gray-ish which is
            // confusing in development
            _hover: {
              background: "brand.light",
              borderColor: "brand.medium",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 0.5,
                color: "brand.medium",
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "white",
              },
            },
          },

          statusPillNotStarted: {
            height: "48px",
            fontWeight: "bold",
            background: "white",
            border: "1px solid",
            borderColor: "brand.medium",

            _disabled: {
              background: "white",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
              color: "white",
            },

            _active: {
              background: "background.light",
              borderColor: "brand.medium",
            },

            _hover: {
              background: "background.light",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "white",
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 1,
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "white",
              },
            },

            color: "brand.medium",
          },

          statusPillIncomplete: {
            height: "48px",
            fontWeight: "bold",
            background: "warning.light",
            border: "1px solid",
            borderColor: "warning.light",

            _disabled: {
              background: "warning.light",
              borderColor: "warning.light",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
            },

            _active: {
              background: "warning.light",
              borderColor: "warning.light",
            },

            _hover: {
              background: "background.light",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "warning.light",
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 1,
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "warning.light",
              },
            },

            color: "warning.dark",
          },

          statusPillRecommended: {
            height: "48px",
            fontWeight: "bold",
            background: "brand.medium",
            border: "1px solid",
            borderColor: "brand.medium",

            _disabled: {
              background: "brand.light",
              borderColor: "brand.light",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
            },

            _active: {
              background: "brand.medium",
              borderColor: "brand.medium",
            },

            _hover: {
              background: "background.light",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "brand.light",
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 1,
                color: "white",
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "brand.medium",
              },
            },

            color: "white",
          },

          statusPillReview: {
            height: "48px",
            fontWeight: "bold",
            background: "background.dark",
            border: "1px solid",
            borderColor: "background.dark",

            _disabled: {
              background: "background.dark",
              borderColor: "background.dark",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
            },

            _active: {
              background: "background.dark",
              borderColor: "background.dark",
            },

            _hover: {
              background: "background.dark",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "background.dark",
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 1,
                color: "text.primary",
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "background.dark",
              },
            },

            color: "text.primary",
          },

          primary: {
            fontWeight: "semibold",
            background: "brand.medium",

            _disabled: {
              background: "brand.light",

              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 1,
              color: "white",
            },

            _active: {
              background: "brand.medium",
              borderColor: "brand.light",
            },

            // Hover won't come into play on mobile, but
            // chakra's default hover is gray-ish which is
            // confusing in development
            _hover: {
              background: "brand.dark",
              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "brand.light",

                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 1,
                color: "white",
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "brand.medium",
              },
            },

            color: "white",
          },
          gray: {
            fontWeight: "bold",
            background: "background.dark",
            color: "text.primary",
            border: "none",

            _disabled: {
              background: "background.dark",
              // By default, chakra sets the opacity to 0.4 for disabled buttons
              opacity: 0.5,
            },

            _active: {
              background: "background.dark",
            },

            // Hover won't come into play on mobile, but
            // chakra's default hover is gray-ish which is
            // confusing in development
            _hover: {
              background: "border.medium",

              // Because of some chakra specificity rules, repeat
              // this gray disabled button rule here too
              _disabled: {
                background: "background.dark",
                // By default, chakra sets the opacity to 0.4 for disabled buttons
                opacity: 0.5,
              },
            },

            // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
            "@media(hover: none)": {
              _hover: {
                background: "border.medium",
              },
            },
          },

          error: {
            fontWeight: "semibold",
            background: "error.medium",
            color: "white",
            border: "none",
            borderRadius: "button",

            _active: {
              background: "error.medium",
            },

            _hover: {
              background: "error.dark",
            },

            _focus: {
              boxShadow: "0 0 0 3px var(--chakra-colors-error-light)",
            },
          },
          warning: {
            fontWeight: "semibold",
            background: "warning.light",
            color: "warning.dark",
            border: "none",

            _active: {
              background: "warning.medium",
            },
            _hover: {
              background: "warning.medium",
            },
            _focus: {
              boxShadow: "0 0 0 3px var(--chakra-colors-warning-light)",
            },
          },

          customIconButton: {
            background: "none",
            _focus: {
              boxShadow: "none",
            },
            _hover: {
              background: "brand.light",
            },
          },

          // (Tomasz) We override button background color on touch vevices not supporting hover with default background color.
          "@media(hover: none)": {
            _hover: {
              background: "error.medium",
            },
          },
        },
      },

      CloseButton: {
        baseStyle: {
          borderRadius: "button",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            background: "background.dark",
          },
          "@media(hover: none)": {
            _hover: {
              background: "background.dark",
            },
          },
        },
        variants: {
          solid: {
            borderRadius: "button",
            _focus: {
              boxShadow: "none",
            },
            _hover: {
              background: "background.dark",
            },
            "@media(hover: none)": {
              _hover: {
                background: "background.dark",
              },
            },
          },
        },
      },

      Alert: {
        baseStyle: {
          title: {
            fontWeight: "semibold",
            color: "text.primary",
          },
          container: {
            borderRadius: "10%",
            border: "1px solid",
            rounded: "full",
          },
        },
        variants: {
          success: {
            container: {
              background: "success.light",
              color: "success.dark",
              borderColor: "success.dark",
            },
          },
          error: {
            container: {
              background: "error.light",
              borderColor: "error.dark",
              color: "error.dark",
            },
          },
        },
      },

      Divider: {
        baseStyle: {
          mt: 3,
          mb: 6,
        },
      },

      Input: {
        backgroundColor: "white",
        baseStyle: {
          field: {
            h: "12",
            fontSize: "lg",
            borderRadius: "lg",
            // Override chakra's box shadow
            boxShadow: "",
            content: {
              fontWeight: "normal",
            },
            _focus: {
              boxShadow: "",
            },
            _disabled: {
              background: "background.dark",
              // Chakra applies opacity 0.4 to disabled fields too,
              // so having the text as black keeps disabled fields more readable
              color: "black",
              _hover: {
                borderColor: "border.medium",
              },
            },
            _placeholder: {
              color: "text.placeholder",
            },
          },
        },
        variants: {
          outline: {
            field: {
              h: "12",
              fontSize: "lg",
              borderColor: "border.medium",
              color: "text.primary",
              content: {
                fontWeight: "normal",
              },
              backgroundColor: "white",
              _focus: {
                borderColor: "brand.medium",
                boxShadow: "",
              },
              _active: {
                borderColor: "brand.medium",
              },
              _hover: {
                borderColor: "brand.medium",
              },
            },
          },
          "outline-warning": {
            field: {
              h: "12",
              borderColor: "warning.dark",
              fontSize: "lg",
              borderWidth: "2px",
              content: {
                fontWeight: "normal",
              },
              _placeholder: {
                fontWeight: "normal",
              },
              _focus: {
                borderColor: "warning.dark",
                boxShadow: "",
              },
              _active: {
                borderColor: "warning.medium",
              },
              _hover: {
                borderColor: "warning.medium",
              },
              _disabled: {
                background: "background.dark",
                // Chakra applies opacity 0.4 to disabled fields too,
                opacity: 0.6,
              },
            },
          },
          "outline-error": {
            field: {
              h: "12",
              fontSize: "lg",
              borderColor: "error.medium",
              borderWidth: "2px",
              content: {
                fontWeight: "normal",
              },
              _placeholder: {
                fontWeight: "normal",
              },
              _focus: {
                borderColor: "error.medium",
                boxShadow: "",
              },
              _active: {
                borderColor: "error.medium",
              },
              _hover: {
                borderColor: "error.medium",
              },
            },
          },
        },
      },

      MenuButton: {
        variants: {
          resourceMenu: {
            backgroundColor: "red",
          },
        },
      },

      Select: {
        backgroundColor: "white",
        variants: {
          outline: {
            field: {
              h: "12",
              fontSize: "lg",
              borderRadius: "lg",
              fontWeight: "normal",
              borderColor: "brand.light",
              _focus: {
                borderColor: "brand.medium",
              },
              _active: {
                boxShadow: "none",
              },
            },
          },
          "outline-warning": {
            field: {
              h: "12",
              fontSize: "lg",
              borderRadius: "lg",
              fontWeight: "normal",
              borderColor: "warning.dark",
              borderWidth: "2px",
              _focus: {
                borderColor: "warning.dark",
              },
            },
          },
          "outline-error": {
            field: {
              h: "12",
              fontSize: "lg",
              borderRadius: "lg",
              fontWeight: "normal",
              borderColor: "error.medium",
              borderWidth: "2px",
              _focus: {
                borderColor: "error.medium",
              },
            },
          },
        },
      },
      Progress: {
        defaultProps: {
          size: "sm",
        },
        baseStyle: {
          filledTrack: {
            bg: "brand.medium",
          },
        },
      },
      CheckmarkListItem: {
        sizes: {
          md: {
            gap: 5,
            my: 5,
            maxWidth: "370px",
            fontWeight: 600,
          },
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: "card",
            borderBottomRadius: { base: 0, md: "card" },
            width: "100%",
            maxWidth: { base: "100%", md: "720px" },
            marginBottom: { base: 0, md: "80px" },
            // minHeight: "300px",
            maxHeight: { base: "appHeight", md: "calc(100vh - 160px)" },
            padding: { md: "48px" },
            marginTop: { md: "80px" },
          },
          dialogContainer: {
            width: "full",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: { base: "flex-end", md: "center" },
          },
        },
        variants: {
          small: {
            dialog: {
              minHeight: { md: undefined },
              maxWidth: { base: "100%", md: "480px" },
              width: { base: "100%", md: "480px" },
            },
            dialogContainer: {},
          },
        },
      },
      Tabs: {
        variants: {
          unpadded: (props: StyleFunctionProps) => ({
            ...props.theme.components.Tabs.variants.line(props),
            ...{
              tabpanel: {
                padding: 0,
                paddingTop: 4,
              },
            },
          }),
        },
      },
    },
    colors: {
      //There is no possibility to use brand colors names in svg stroke and we need to pass there hex format
      white: "#FFFFFF",
      background: {
        light: "#F9FAFB",
        dark: "#F2F4F7",
      },
      text: {
        secondary: "#475467",
        primary: "#293056",
        placeholder: "#667085",
      },
      brand: {
        light: "#ECF1FF",
        medium: "#0047FF", // This is the brand main color
        dark: "#0239C9",
      },
      border: {
        medium: "#D0D5DD",
      },
      success: {
        light: "#ECF8F7",
        medium: "#1AB092",
        dark: "#1E6D5D",
      },
      warning: {
        light: "#FEF0C7",
        medium: "#FEDF89",
        dark: "#B54708",
      },
      error: {
        light: "#FFEDEC",
        medium: "#E04F4F",
        dark: "#C03E46",
      },
      screenBuilder: {
        background: "#eee",
      },
      // using this for the 'prefill' color, but we plan to clean this up (COL-22414)
      prefill: {
        light: "#ECF1FF",
        medium: "#0047FF",
        dark: "#0239C9",
      },
    },
  },
  catalogTheme,
);

export default theme;
