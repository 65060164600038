import React, { useCallback, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Link,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import { throttle } from "lodash";

import {
  checkForDeviceIdAuthentication,
  sendPhoneVerificationCode,
  verifyPhone,
} from "../api";
import { AuthScreenProps } from "../types";

import { NotAuthorizedError, RateLimitExceededError } from "../../shared/api";

import ContentContainerWrapper from "../components/ContentContainerWrapper";
import { Label } from "../components/InputBlockLabel";
import LoadingBlock from "../components/LoadingBlock";
import NavHeader from "../components/NavHeader";
import ResponsiveContentWrapper from "../components/ResponsiveContentWrapper";
import StyledInput from "../components/StyledInput";
import SubTitle from "../components/SubTitle";

import { FeatureFlagContext } from "../../App";
import { getOrComputeDeviceId } from "../../getDeviceId";
import rollbar from "../../rollbar-utils";
import { updateUserParamsFromUrl } from "../../utils/params-utils";
import { openZendesk } from "../../zendesk";

enum ScreenState {
  REGISTER_PHONE,
  VERIFY_PHONE,
}

enum VerificationError {
  INVALID_CODE = "INVALID_CODE",
  RATE_LIMIT_EXCEEDED = "RATE_LIMIT_EXCEEDED",
  INVALID_CODE_LENGTH = "INVALID_CODE_LENGTH",
}

const HelpLink = ({ children }: { children: React.ReactNode }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const useZendeskSandbox = !!(featureFlags && featureFlags.useZendeskSandbox);
  const useZendeskProductionMessaging = !!(
    featureFlags && featureFlags.useZendeskProductionMessaging
  );

  const useZendeskProductionClassic = !(
    useZendeskSandbox || useZendeskProductionMessaging
  );
  return (
    <Link
      aria-label="support"
      onClick={() => {
        openZendesk(useZendeskProductionClassic);
      }}
    >
      {children}
    </Link>
  );
};

const verificationErrorMessage = (verificationError: VerificationError) => {
  switch (verificationError) {
    case VerificationError.INVALID_CODE:
      return (
        <>
          This doesn't look like the right code. Try sending another one or{" "}
          <Text as="u" fontWeight="semibold">
            <HelpLink>contact support</HelpLink>
          </Text>{" "}
          for help.
        </>
      );
    case VerificationError.RATE_LIMIT_EXCEEDED:
      return (
        <>
          You made too many attempts. Please come back later to try again or{" "}
          <Text as="u" fontWeight="semibold">
            <HelpLink>contact support</HelpLink>
          </Text>{" "}
          for help.
        </>
      );
    case VerificationError.INVALID_CODE_LENGTH:
      return <Text>Expected a 6 digit code.</Text>;
    default:
      console.error(`Unhandled error type: ${verificationError}`);
  }
};

const AuthScreen: React.FC<AuthScreenProps> = (props: AuthScreenProps) => {
  const { supportEnabled, screen, onBack, onSubmit } = props;

  const [screenState, setScreenState] = useState<ScreenState>(
    ScreenState.REGISTER_PHONE,
  );

  const hasVerifiedPhoneNumber = screen.maskedVerifiedPhoneNumber !== null;

  // If we have a verified phone number for the user, use that. It will be masked
  // to only show the last 4 digits.
  //
  // Otherwise, we can try to pre-fill this number if we happen to have it passed
  // in from our partner.
  //
  // Otherwise, fallback to letting the user type somthing in from scratch.
  const [phoneNumber, setPhoneNumber] = useState<string>(
    screen.maskedVerifiedPhoneNumber || screen.prefilledPhoneNumber || "",
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [sendingVerificationCode, setSendingVerificationCode] =
    useState<boolean>(false);
  const [sendingVerificationCodeViaLink, setSendingVerificationCodeViaLink] =
    useState<boolean>(false);

  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verifyingValidationCode, setVerifyingValidationCode] =
    useState<boolean>(false);
  const [verificationError, setVerificationError] =
    useState<VerificationError | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // On mount, check for a device-id authentication match. If so, proceed to the next screen.
  useEffect(() => {
    const checkAuthentication = async () => {
      // set the screen to loading while we check the backend
      setIsLoading(true);

      try {
        const deviceId = await getOrComputeDeviceId();
        if (deviceId === null) {
          return;
        }
        const deviceIdAuthResponse = await checkForDeviceIdAuthentication(
          deviceId,
        );

        // we only care if the result succeeded -- if not, let the user continue
        if (deviceIdAuthResponse.success === true) {
          if (deviceIdAuthResponse.type === "url") {
            window.history.replaceState({}, "", deviceIdAuthResponse.url);
            updateUserParamsFromUrl();
          }
          // Proceed with the next action after successful authentication
          // we keep the loading screen through redirect, so it doesn't flash between screens
          onSubmit(screen.targetNavigateAction);
        } else {
          // turn the loading screen off if we didn't login via device-id
          setIsLoading(false);
        }
      } catch (error) {
        rollbar.warn("Device Authentication check failed");
        // turn the loading screen off there's an error
        setIsLoading(false);
      }
    };

    if (screen.allowDeviceVerification) {
      checkAuthentication();
    } else {
      setIsLoading(false);
    }
  }, []);

  const toast = useToast();

  const handleVerificationCodeChange = async (code: string) => {
    if (code.length === 6) {
      try {
        setVerifyingValidationCode(true);
        const response = await verifyPhone(phoneNumber, code);
        // TODO-AUTH(nihar/billy): should we handle cookies here?
        if (response.type === "url") {
          window.history.replaceState({}, "", response.url);
          updateUserParamsFromUrl();
          // Proceed with the next action after successful verification
          onSubmit(screen.targetNavigateAction);

          toast({
            title: "You're all set.",
            status: "success",
            position: "top",
            duration: 3000,
            variant: "success",
            isClosable: true,
          });
        } else {
          throw new Error("Verification failed");
        }
      } catch (error) {
        setVerificationCode(""); // Clear the verification code input

        if (error instanceof NotAuthorizedError) {
          setVerificationError(VerificationError.INVALID_CODE);
        } else if (error instanceof RateLimitExceededError) {
          setVerificationError(VerificationError.RATE_LIMIT_EXCEEDED);
        }
      } finally {
        setVerifyingValidationCode(false);
      }
    }
  };

  const handleSendPhoneVerificationCode = async () => {
    try {
      setSendingVerificationCode(true);
      const response = await sendPhoneVerificationCode(phoneNumber);

      if (response.type === "error") {
        setPhoneNumberError(response.errors[0]);
      } else {
        setScreenState(ScreenState.VERIFY_PHONE);
      }
    } finally {
      setSendingVerificationCode(false);
    }
  };

  // Throttle sending the coded so that the user cannot spam the button
  const throttledHandleSendPhoneVerificationCode = useCallback(
    throttle(handleSendPhoneVerificationCode, 2000),
    [phoneNumber],
  );

  const onVerificationCodeSubmission = async () => {
    // The code should be automatically submitted once its 6 digits long.
    // If it's too short, give the user feedback to help them submit.
    if (verificationCode.length != 6) {
      setVerificationError(VerificationError.INVALID_CODE_LENGTH);
    }
  };

  const resetScreenToRegisterState = () => {
    // we don't have to reset the phone, but we do have to reset the errors
    setScreenState(ScreenState.REGISTER_PHONE);
    setVerificationCode("");
    setVerificationError(null);
  };

  let content: {
    title: string;
    subTitle: string;
    onSubmit: () => Promise<void>;
    input: React.ReactNode;
    actions: React.ReactNode;
  };

  if (screenState === ScreenState.REGISTER_PHONE) {
    content = {
      title: hasVerifiedPhoneNumber
        ? "Let's make sure it's you"
        : "Verify your phone number",
      subTitle: hasVerifiedPhoneNumber
        ? "We'll send you a one-time verification number to your phone number."
        : "We'll send you a verification code whenever you come back to Column Tax. Make sure you add a number that can accept text messages.",
      input: (
        <Box mt={8}>
          <Box mb={2}>
            <Label label="Your mobile phone number" />
          </Box>
          {hasVerifiedPhoneNumber ? (
            <Box>
              <Input type="tel" value={phoneNumber} disabled />

              <Box mt={2}>
                <Text color="brand.medium">
                  <HelpLink>Need help? Contact us.</HelpLink>
                </Text>
              </Box>
            </Box>
          ) : (
            <FormControl isInvalid={phoneNumberError !== null}>
              <NumberFormat
                type="tel"
                format="(###) ###-####"
                placeholder="e.g. (123) 456-7890"
                mask="_"
                required
                customInput={StyledInput}
                value={phoneNumber}
                onValueChange={(value) => {
                  if (phoneNumberError) {
                    setPhoneNumberError(null);
                  }

                  setPhoneNumber(value.value);
                }}
              />
              {phoneNumberError !== null && (
                <Text color="red.500">{phoneNumberError}</Text>
              )}
            </FormControl>
          )}
        </Box>
      ),
      onSubmit: async () => throttledHandleSendPhoneVerificationCode(),
      actions: (
        <Flex
          flexDirection="row"
          flexWrap="wrap-reverse"
          gap={4}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="primary"
            w={"full"}
            px={8}
            size={"md"}
            maxW={{ md: "300px" }}
            mt={4}
            isDisabled={sendingVerificationCode || phoneNumber.length < 10}
            onClick={() => throttledHandleSendPhoneVerificationCode()}
          >
            {sendingVerificationCode ? "Sending Code..." : "Send Code"}
          </Button>
        </Flex>
      ),
    };
  } else {
    content = {
      title: "We just sent you a code",
      subTitle: `Enter the 6-digit code sent to <b>${phoneNumber}</b>. It might take a minute to show up`,
      input: (
        <Box mt={8}>
          <Box mb={2}>
            <Label label="Verification code" />
          </Box>
          <FormControl
            isInvalid={verificationError !== null}
            isDisabled={
              verifyingValidationCode ||
              verificationError === VerificationError.RATE_LIMIT_EXCEEDED
            }
          >
            <NumberFormat
              id="single-factor-code-text-field"
              autoComplete="one-time-code"
              format="######"
              mask="_"
              required
              value={verificationCode}
              onValueChange={(value) => {
                if (verificationError !== null && value.formattedValue !== "") {
                  setVerificationError(null);
                }
                setVerificationCode(value.value);
                handleVerificationCodeChange(value.value);
              }}
              customInput={StyledInput}
            />
            <Box mt={2}>
              {verificationError !== null && (
                <Text color="red.500">
                  {verificationErrorMessage(verificationError)}
                </Text>
              )}
              {verificationError !== VerificationError.RATE_LIMIT_EXCEEDED && (
                <Link
                  color="brand.medium"
                  onClick={async () => {
                    // Don't allow sending too fast
                    if (sendingVerificationCodeViaLink) {
                      return;
                    }

                    setSendingVerificationCodeViaLink(true);
                    await sendPhoneVerificationCode(phoneNumber);
                    setSendingVerificationCodeViaLink(false);
                  }}
                >
                  {sendingVerificationCodeViaLink
                    ? "Sending code..."
                    : "Didn't receive a code? Send again"}
                </Link>
              )}
            </Box>
          </FormControl>
        </Box>
      ),
      onSubmit: async () => {
        // This doesn't actually submit the code, that happens automatically when 6 digits
        // were entered
        onVerificationCodeSubmission();
      },
      actions: (
        <>
          <Flex
            w="full"
            gridColumnGap={3}
            gridRowGap={4}
            justifyContent="center"
            flexDirection={{
              base: "row",
              md: "row",
            }}
          >
            <Button
              mt={{ base: 0, md: 0 }}
              mr={undefined}
              variant="gray"
              px={8}
              size={"md"}
              aria-label="Back CTA"
              maxW="160px"
              onClick={() => resetScreenToRegisterState()}
            >
              Back
            </Button>
            <Button
              variant="primary"
              w={"full"}
              px={8}
              size={"md"}
              maxW={{ md: "300px" }}
              onClick={() => {
                onVerificationCodeSubmission();
              }}
              isDisabled={
                verifyingValidationCode ||
                verificationError === VerificationError.RATE_LIMIT_EXCEEDED
              }
            >
              {verifyingValidationCode ? <Spinner /> : "Verify"}
            </Button>
          </Flex>
        </>
      ),
    };
  }

  return (
    <Box w="full" flexDirection="column" zIndex={1}>
      {isLoading ? (
        <LoadingBlock />
      ) : (
        <>
          <NavHeader
            bannerItems={screen.bannerItems}
            renderBack={true}
            tempScreenId={screen.id}
            supportEnabled={supportEnabled}
            showDesktopTest={screen.desktopIcon}
            screen={screen}
            onSubmit={onSubmit}
            onBack={
              screenState === ScreenState.REGISTER_PHONE
                ? onBack
                : async () => {
                    setScreenState(ScreenState.REGISTER_PHONE);
                  }
            }
          />
          <ResponsiveContentWrapper>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={{ base: "space-between", md: "flex-start" }}
              gap={4}
              flex={{ base: 1, md: 0 }}
            >
              <Flex
                direction="column"
                height={{ base: "full", md: "auto" }}
                minHeight={{ base: "full", md: undefined }}
                flex={{ base: 1, md: 0 }}
                gridRowGap={8}
              >
                <ContentContainerWrapper>
                  <Flex flexDirection="column" gridRowGap={4}>
                    <Heading color="text.primary" my={0}>
                      {content.title}
                    </Heading>
                    <SubTitle text={content.subTitle} color="text.primary" />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    gap={8}
                    justifyContent="space-between"
                    flex={1}
                  >
                    {/* This form helps the browser with autocomplete */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        content.onSubmit();
                      }}
                    >
                      {content.input}
                    </form>
                  </Flex>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    marginTop={{ base: 6, md: 8 }}
                  >
                    <Box
                      display={{ base: "none", md: "flex" }}
                      paddingTop={12}
                      height={0}
                      width="full"
                    />
                    {content.actions}
                  </Box>
                </ContentContainerWrapper>
              </Flex>
            </Box>
          </ResponsiveContentWrapper>
        </>
      )}
      ;
    </Box>
  );
};

export default AuthScreen;
