import React, { useContext } from "react";

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import {
  ActionType,
  HandleSubmitType,
  NavigationChoiceType,
  Resource,
  ResourceType,
} from "../types";

import { FeatureFlagContext } from "../../App";
import { openZendesk } from "../../zendesk";
import QuestionIcon from "../icons/QuestionIcon";
import ExpertAssistCallScheduleModalBody from "./ExpertAssistCallScheduleModalBody";

interface SupportHeaderProps {
  screenId: string;
  onSubmit: HandleSubmitType | undefined;
  resource?: Resource;
  enrolledInExpertAssist: boolean;
}

const SupportHeader = ({
  screenId,
  onSubmit,
  resource,
  enrolledInExpertAssist,
}: SupportHeaderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const featureFlags = useContext(FeatureFlagContext);
  const useZendeskSandbox = !!(featureFlags && featureFlags.useZendeskSandbox);
  const useZendeskProductionMessaging = !!(
    featureFlags && featureFlags.useZendeskProductionMessaging
  );

  const useZendeskProductionClassic = !(
    useZendeskSandbox || useZendeskProductionMessaging
  );
  // STOPLAUNCH(marcia): This screen id should come from the BE, since it
  // will change to TY24 onward.
  const landingScreenId = "TY23::Screens::ExpertAssistScreen";
  const fallbackScreenId = "TY23::Screens::FallbackLoadingScreen";
  const theme = useTheme();
  const fillColor =
    screenId === landingScreenId
      ? theme.colors.brand.medium
      : theme.colors.text.primary;

  const handleClick = () => {
    sendEventButtonClick({
      buttonName: "NavBarQuestionMark",
      screenId: screenId,
      expertAssistEnrolled: enrolledInExpertAssist,
    });
    if (enrolledInExpertAssist) {
      if (screenId === fallbackScreenId) {
        onOpen();
      } else if (screenId !== landingScreenId && onSubmit) {
        // Check if we are on the landing screen. If we are not already
        // there, navigate to it!
        onSubmit({
          navigationChoice: landingScreenId,
          navigationChoiceType: NavigationChoiceType.Screen,
          type: ActionType.NAVIGATE,
          resource: resource,
          resourceType: ResourceType.Target,
        });
      }
    } else {
      openZendesk(useZendeskProductionClassic);
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />

        <ModalContent
          maxH="calc(100% - 24px)" // 24px boundary from https://linear.app/column-tax/issue/COL-6060#comment-91353658
        >
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 0 }}>
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
            >
              <ExpertAssistCallScheduleModalBody />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <IconButton
        key={screenId}
        display="inline-flex"
        w={12}
        h={12}
        background="none"
        variant="unstyled"
        aria-label="support"
        icon={<QuestionIcon fillColor={fillColor} />}
        onClick={handleClick}
        _active={{ color: "border.medium" }}
        css
      />
    </>
  );
};

export default SupportHeader;
