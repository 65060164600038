import React from "react";

import {
  Box,
  Button,
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { sendEventOpenHelpModal } from "../api";
import { OpenHelpTypes } from "../types";

// TODO(marcia): Consolidate all DIY modals to use this modal for consistent UI
// - Customer support modal
// - Exit modal
// - Any other modals that may be lingering when this todo is todone!
// TODO(marcia): Remove these inline styles and put into theme/index.tsx
function Modal({
  openEventProperties,
  openText,
  icon,
  body,
  closeButtonLabel,
  noMargins,
}: {
  openEventProperties: {
    openKey: string;
    openType: OpenHelpTypes;
    screenId: string;
  };
  openText?: string | JSX.Element;
  icon?: JSX.Element;
  body: JSX.Element;
  closeButtonLabel?: string;
  noMargins?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openContent =
    typeof openText === "string" ? (
      <Text
        as="span"
        color="brand.medium"
        size="sm"
        cursor="pointer"
        _hover={{
          textDecoration: "underline",
        }}
        dangerouslySetInnerHTML={{ __html: openText }}
      ></Text>
    ) : (
      openText
    );

  return (
    <>
      <Flex
        onClick={() => {
          onOpen();
          sendEventOpenHelpModal(openEventProperties);
        }}
        alignItems="center"
        mt={noMargins ? undefined : "3"}
        fontWeight="medium"
      >
        {icon}
        {openContent}
      </Flex>
      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxH="calc(100% - 24px)" // 24px boundary from https://linear.app/column-tax/issue/COL-6060#comment-91353658
        >
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 0 }}>
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
            >
              <Box>
                {React.cloneElement(body, { onClose: onClose })}
                {closeButtonLabel && (
                  <Button variant="gray" width="full" mb={12} onClick={onClose}>
                    {closeButtonLabel}
                  </Button>
                )}
              </Box>
              {/* TODO(marcia): Add a button to contact support modal here*/}
              {/* <Button onClick={onClose} width="full" mb="5">
                Got it
              </Button> */}
            </Flex>
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}

export default Modal;
