import React from "react";

function BackIcon() {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5833 13.76H5.41667M5.41667 13.76L13 21.3433M5.41667 13.76L13 6.17667"
        stroke="#293056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BackIcon;
