import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { ActionType, CTA, MiniManageScreenProps, Resource } from "../types";

import Card from "../../components/Card";
import BlockRenderer from "../components/BlockRenderer";
import ManageDeleteModal from "../components/ManageDeleteModal";

import PlusCircle from "../icons/PlusCircle";
import TrashCan from "../icons/TrashCan";
import QuestionScreenHOC from "./QuestionTypeScreenWrapper";
import {
  indefiniteArticle,
  initializeBlockAnswers,
  resetScreen,
  setBlockAnswer,
} from "./screenUtils";

const MiniManageScreen: React.FC<MiniManageScreenProps> = (
  props: MiniManageScreenProps,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { screen, onSubmit, setScreen } = props;
  const [loadingButton, setLoadingButton] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleNext = async () => {
    setIsDisabled(true);
    await onSubmit({ type: ActionType.ANSWER });
  };

  const addResource = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setIsDisabled && setIsDisabled(true);
      setLoadingButton(CTA.MANAGE_ADD);

      const formValues = getValues();

      screen.miniManagedBlocks?.forEach((miniManageBlock) => {
        miniManageBlock.forEach((block) =>
          setBlockAnswer(block, formValues, dirtyFields),
        );
      });

      onSubmit({ type: ActionType.ADD });
    }
  };

  const addButtonText = `Add ${indefiniteArticle(
    screen.addAnotherLabel || "",
  )} ${screen.addAnotherLabel || ""}`;

  const formMethods = useForm({ mode: "onTouched" });

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    control,
    setError,
    reset,
  } = formMethods;

  useEffect(() => {
    resetScreen(timer, setIsDisabled, setLoadingButton, ref);
    reset();

    // Initialize react-hook-form values and errors from
    // BE response
    return screen.miniManagedBlocks?.forEach((blockArray) => {
      blockArray.forEach((block) => {
        return initializeBlockAnswers(block, setValue, setError);
      });
    });
  }, [reset, screen.blocks]);

  function onDelete(resource?: Resource) {
    if (resource) {
      const formValues = getValues();

      screen.miniManagedBlocks?.forEach((miniManageBlock) => {
        miniManageBlock.forEach((block) =>
          setBlockAnswer(block, formValues, dirtyFields),
        );
      });

      onSubmit({
        type: ActionType.DELETE,
        resource: resource?.uuid,
      });
      onClose();
    }
  }

  function save() {
    const formValues = getValues();

    screen.miniManagedBlocks?.forEach((miniManageBlock) => {
      miniManageBlock.forEach((block) =>
        setBlockAnswer(block, formValues, dirtyFields),
      );
    });

    handleNext();
  }

  const formObject = {
    handleSubmit: handleSubmit,
    control: control,
    register: register,
    watch: watch,
    setValue: setValue,
    errors: errors,
  };

  return (
    <QuestionScreenHOC
      ref={ref}
      isDisabled={isDisabled}
      loadingButton={loadingButton}
      setIsDisabled={setIsDisabled}
      setLoadingButton={setLoadingButton}
      questionScreenProps={props}
      timer={timer}
      save={save}
      formMethods={formMethods}
      children={
        <>
          {screen.miniManagedBlocks?.map((blks, index) => {
            const resource = screen.resources?.[index];
            return (
              <Flex key={index} flexDirection="column" gridRowGap={8}>
                <Card>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    background="background.light"
                    px={5}
                    borderTopRadius="card"
                  >
                    <Text fontWeight="semibold">{screen.title}</Text>
                    <IconButton
                      background="none"
                      data-manage-delete
                      variant="gray"
                      onClick={onOpen}
                      aria-label={"Delete"}
                      title={"Delete"}
                      icon={<TrashCan />}
                    />
                  </Flex>
                  <Flex flexDirection="column" gridRowGap={8} p={5}>
                    <BlockRenderer
                      blocks={blks}
                      onSubmit={handleNext}
                      setScreen={setScreen}
                      screen={screen}
                      formObject={formObject}
                      isLoading={loadingButton !== ""}
                    />
                  </Flex>
                  <ManageDeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    resourceName={screen.addAnotherLabel || ""}
                    onDelete={() => onDelete(resource)}
                  />
                </Card>
              </Flex>
            );
          })}

          <Box display="flex" flexDirection="row" justifyContent="center">
            <Button
              width="100%"
              variant="outline"
              rounded="button"
              onClick={!isDisabled ? addResource : undefined}
              height={14}
              maxW="300px"
              isLoading={loadingButton === CTA.MANAGE_ADD}
              isDisabled={isDisabled}
            >
              <Flex p="3" alignItems="center">
                <PlusCircle />
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign="left"
                  marginLeft="3"
                >
                  {addButtonText}
                </Text>
              </Flex>
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default MiniManageScreen;
