import React from "react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

import {
  OffSeasonNotSubmittedSummary,
  OffSeasonSubmissionSummary,
  OffSeasonSubmittedSummary,
  Resource,
} from "../types";

import Card from "../../components/Card";

import { authenticateAndOpenPDF } from "../../utils/nav-utils";
import OffSeasonInfoBlockHeader from "./OffSeasonInfoBlockHeader";

interface Props {
  blockTitle: string;
  summary: OffSeasonSubmissionSummary;
  onSubmit: (screenId: string, resource?: Resource | null) => string;
}

const OffSeasonTaxSummaryBlock: React.FC<Props> = ({
  blockTitle,
  summary,
  onSubmit,
}) => {
  const NotSubmittedSummary = ({
    summary,
  }: {
    summary: OffSeasonNotSubmittedSummary;
  }) => {
    return (
      <Flex px={8} pb={8} justifyContent="left" justifyItems="center">
        <Text>{summary.text}</Text>
      </Flex>
    );
  };

  const SubmittedSummary = ({
    summary,
  }: {
    summary: OffSeasonSubmittedSummary;
  }) => {
    const { pdfLink, viewReturnButton } = summary;

    return (
      <>
        <Box w="full">
          <Flex w="full" justifyContent="center" alignItems="center">
            <Flex
              grow={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pb={8}
            >
              <Button
                w={"full"}
                px={8}
                form={viewReturnButton.id}
                variant="outline"
                onClick={() =>
                  onSubmit(viewReturnButton.screenId, viewReturnButton.resource)
                }
                isLoading={false}
                loadingText="View Return Details"
                maxWidth={{ md: "300px", base: "80%" }}
              >
                View Return Details
              </Button>
            </Flex>
          </Flex>
        </Box>
        {pdfLink && (
          <Box
            pl={{ base: 7, md: 8 }}
            pr={{ base: 7, md: 8 }}
            pb={4}
            pt={4}
            width="full"
            backgroundColor="background.dark"
            fontSize="md"
            onClick={() => {
              authenticateAndOpenPDF(pdfLink);
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="semibold" color="text.primary">
                View Return PDF
              </Text>
              <ArrowForwardIcon boxSize={6} />
            </Flex>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box mb={{ base: 0, md: 8 }}>
      <Card>
        <Box
          p={{ base: 7, md: 8 }}
          display="flex"
          justifyContent={{ base: "space-between", md: "space-between" }}
          width="full"
          alignItems="center"
        >
          <OffSeasonInfoBlockHeader
            blockTitle={blockTitle}
            pillInfo={
              summary.type === "not_submitted_summary"
                ? "Not submitted"
                : undefined
            }
          />
        </Box>
        {summary.type === "submitted_summary" && (
          <SubmittedSummary summary={summary} />
        )}
        {summary.type === "not_submitted_summary" && (
          <NotSubmittedSummary summary={summary} />
        )}
      </Card>
    </Box>
  );
};

export default OffSeasonTaxSummaryBlock;
