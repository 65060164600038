import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Flex } from "@chakra-ui/react";

import { ActionType, CTA, QuestionScreenProps, UploadType } from "../types";

import BlockRenderer from "../components/BlockRenderer";

import QuestionScreenHOC from "./QuestionTypeScreenWrapper";
import {
  initializeBlockAnswers,
  resetScreen,
  setBlockAnswer,
} from "./screenUtils";

const QuestionScreen: React.FC<QuestionScreenProps> = (
  props: QuestionScreenProps,
) => {
  const { screen, onSubmit, setScreen } = props;
  const [loadingButton, setLoadingButton] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const formMethods = useForm({ mode: "onTouched" });

  const handleNext = async () => {
    setIsDisabled(true);
    timer.current = setTimeout(() => {
      setLoadingButton(CTA.CONTINUE);
    }, 250);

    await onSubmit({ type: ActionType.ANSWER });
  };

  // TODO(marcia): Our onSubmit / handleFileUpload are slightly duplicated
  // between QuestionScreen and NavigationScreen. Untangle these
  const handleFileUpload = async ({
    file,
    uploadType,
  }: {
    file: File;
    uploadType: UploadType;
  }) => {
    await onSubmit({
      type: ActionType.UPLOAD_W2,
      file: file,
      resource: screen.resource,
      uploadType: uploadType,
    });
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    control,
    reset,
    setError,
  } = formMethods;

  function save() {
    const formValues = getValues();

    // Write react-hook-form form values back to screen.blocks when we
    // send the values back to BE
    // TODO(marcia): We might not need to write back to screen.blocks
    // but this works for now
    screen.blocks.forEach((block) => {
      setBlockAnswer(block, formValues, dirtyFields);
    });

    handleNext();
  }

  useEffect(() => {
    resetScreen(timer, setIsDisabled, setLoadingButton, ref);
    reset();

    // Initialize react-hook-form values and errors from
    // BE response
    screen.blocks.forEach((block) => {
      return initializeBlockAnswers(block, setValue, setError);
    });
  }, [reset, screen.blocks]);

  const formObject = {
    handleSubmit: handleSubmit,
    control: control,
    register: register,
    watch: watch,
    setValue: setValue,
    errors: errors,
  };

  return (
    <QuestionScreenHOC
      ref={ref}
      isDisabled={isDisabled}
      loadingButton={loadingButton}
      setIsDisabled={setIsDisabled}
      setLoadingButton={setLoadingButton}
      questionScreenProps={props}
      timer={timer}
      save={save}
      formMethods={formMethods}
      children={
        <Flex flexDirection="column" gridRowGap={8}>
          <BlockRenderer
            blocks={screen.blocks}
            onSubmit={handleNext}
            onFileUpload={handleFileUpload}
            setScreen={setScreen}
            screen={screen}
            formObject={formObject}
            isLoading={loadingButton !== ""}
          />
        </Flex>
      }
    />
  );
};

export default QuestionScreen;
