import React from "react";

import { Box, Heading, Text } from "@chakra-ui/react";

export const CallExpertModalBody: React.FC = () => {
  return (
    <Box pt={4}>
      <Heading size="lg" variant="modalBodyHeader">
        Call an expert
      </Heading>

      <Text variant="modalBodyText">
        To get live help from an expert, give us a call at{" "}
        <b>(619) 393 - 5971</b>. We’re available to take your call Monday –
        Friday, 10am – 5pm ET.
      </Text>
    </Box>
  );
};

export default CallExpertModalBody;
