import React, { useState } from "react";

import { Center, Circle, Flex, Image, Text } from "@chakra-ui/react";

import { ReviewAndFileBlock } from "../types";

import TickIconSVG from "../icons/tick_icon.svg";
import ChoiceOutline from "./ChoiceOutline";
import DefaultNavigationChoiceItem from "./DefaultNavigationChoiceItem";
import NavigationChoiceHOC from "./NavigationChoiceHOC";

function filingFeeComponent({
  totalFeesLabel,
  resubmissionLabel,
  alreadyPaidLabel,
}: {
  totalFeesLabel: null | string;
  resubmissionLabel: null | string;
  alreadyPaidLabel: null | string;
}) {
  // If there are no labels, render nothing -- we are straddling two
  // worlds pre/post charging users
  if (!resubmissionLabel && !totalFeesLabel && !alreadyPaidLabel) {
    return <></>;
  }

  let inner: JSX.Element = <></>;
  if (resubmissionLabel) {
    inner = <Text>{resubmissionLabel}</Text>;
  } else if (alreadyPaidLabel) {
    inner = (
      <Flex alignItems="center">
        <Center display="inline-block" verticalAlign="middle" mr="2">
          <Circle bg="success.dark" size="20px">
            <Image src={TickIconSVG} width="14px" />
          </Circle>
        </Center>
        <Text>{alreadyPaidLabel}</Text>
      </Flex>
    );
  } else if (totalFeesLabel) {
    inner = (
      <>
        <Text fontSize="md" fontWeight="medium">
          Total filing fee:
        </Text>
        <Text fontWeight="semibold">{totalFeesLabel}</Text>
      </>
    );
  }

  return (
    <Flex
      direction="row"
      justifyContent={
        resubmissionLabel || alreadyPaidLabel ? "center" : "space-between"
      }
      bgColor="gray.50"
      py="4"
      px="7"
      roundedBottom="card"
    >
      {inner}
    </Flex>
  );
}

function ReviewAndFile(props: {
  choice: ReviewAndFileBlock;
  color?: string;
  fontWeight?: string;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
  onClick: () => void;
  totalFeesLabel: null | string;
  resubmissionLabel: null | string;
  alreadyPaidLabel: null | string;
}) {
  const { choice, totalFeesLabel, resubmissionLabel, alreadyPaidLabel } = props;

  const { isEnabled, readyToRefile } = choice;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <NavigationChoiceHOC
      setIsLoading={setIsLoading}
      setIsDisabled={setIsDisabled}
      isEnabled={isEnabled}
      {...props}
      item={
        <ChoiceOutline readyToRefile={readyToRefile}>
          <>
            <DefaultNavigationChoiceItem
              isLoading={isLoading}
              isDisabled={isDisabled}
              {...props}
            />
            {filingFeeComponent({
              totalFeesLabel: totalFeesLabel,
              resubmissionLabel,
              alreadyPaidLabel,
            })}
          </>
        </ChoiceOutline>
      }
    />
  );
}

export default ReviewAndFile;
