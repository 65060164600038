import React from "react";

import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";

import { convertToExternalBrowserUrl } from "../../utils/nav-utils";

// currently (Sept 2024), ExpertAssistCallScheduleModalBody is shown to Expert Assist users in a modal if they are in the fallback experience
export const ExpertAssistCallScheduleModalBody: React.FC = () => {
  return (
    <Box pt={4}>
      <Heading size="lg" variant="modalBodyHeader">
        Expert Assist
      </Heading>

      <Flex flexDir="column" gridGap="4" pt="2">
        <Flex flexDir="column">
          <Heading size="h4" fontWeight="bold">
            Call an expert
          </Heading>
          <Text>
            To get live help from an expert, give us a call at{" "}
            <b>(619) 393 - 5971</b>. We’re available to take your call Monday –
            Friday, 10am – 5pm ET.
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Heading size="h4" fontWeight="bold">
            Schedule a call
          </Heading>
          <Text>
            To schedule a call at a time that works best for you,{" "}
            <Link
              target="_blank"
              href={convertToExternalBrowserUrl(
                "https://calendly.com/lori-30min/taxpayer-support-session",
              )}
              textColor="brand.medium"
            >
              visit this link
            </Link>
            .
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ExpertAssistCallScheduleModalBody;
