import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { CheckmarkItem } from "../types";

import CheckmarkList from "../components/CheckmarkList";

import coinImage from "../images/coin_image.png";
import OffSeasonInfoBlockHeader from "./OffSeasonInfoBlockHeader";

interface Props {
  blockTitle: string;
  caption: string;
  filingOpenDate: string;
  checkmarkListItems?: CheckmarkItem[];
}

const OffSeasonInfoBlock: React.FC<Props> = ({
  blockTitle,
  caption,
  filingOpenDate,
  checkmarkListItems,
}) => {
  const shouldRenderCheckmarkList = !!checkmarkListItems;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={{ base: 8, md: 6 }}
    >
      <Box rounded="card" boxShadow="card" backgroundColor="white">
        <Box
          p={{ base: 7, md: 8 }}
          display="flex"
          justifyContent={{ base: "space-between", md: "space-between" }}
          width="full"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={7}
          >
            <OffSeasonInfoBlockHeader
              blockTitle={blockTitle}
              pillInfo="Not open"
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              maxW={{ md: "400px" }}
              pr={{ md: "48px" }}
            >
              <Text fontSize="sm">{caption}</Text>

              <Image
                width="72px"
                height="72px"
                src={coinImage}
                display={{ md: "none" }}
                alt="Coin Image"
              />
            </Box>
          </Box>
          <Image
            width="104px"
            height="104px"
            src={coinImage}
            display={{ base: "none", md: "flex" }}
            alt="Coin Image"
          />
        </Box>

        <Box
          pl={{ base: 7, md: 8 }}
          pr={{ base: 7, md: 8 }}
          pb={4}
          pt={4}
          width="full"
          backgroundColor="background.light"
          fontSize="md"
        >
          <Flex alignItems="right">
            <Text fontWeight="semibold" color="text.primary" pr={1}>
              Filing opens:{" "}
            </Text>
            <Text> {filingOpenDate}</Text>
          </Flex>
        </Box>
      </Box>
      {shouldRenderCheckmarkList && (
        <Box
          rounded={{ base: "none", md: "card" }}
          boxShadow={{ base: "none", md: "card" }}
          backgroundColor={{ base: "none", md: "white" }}
          padding={{ base: 0, md: 8 }}
        >
          <CheckmarkList mx={{ md: "left" }} maxWidth="maxWidth">
            {checkmarkListItems}
          </CheckmarkList>
        </Box>
      )}
    </Box>
  );
};

export default OffSeasonInfoBlock;
