import React from "react";

import { Box, Image, Text } from "@chakra-ui/react";

import { MilestoneStep } from "../types";

import TickIconSVG from "../icons/tick_icon.svg";

interface ItemProps {
  step: MilestoneStep;
  index: number;
  length: number;
}

interface Props {
  steps?: Array<MilestoneStep>;
}

const MilestoneStepItem: React.FC<ItemProps> = ({ step, index, length }) => {
  if (!step) return null;
  const isNext = step.next;
  const isLast = index + 1 === length;
  const iconOutlineColor = step.complete
    ? "brand.medium"
    : isNext
    ? "brand.medium" // The next suggested (but incomplete) step has light blue outline
    : "border.medium"; // The rest have it just grey

  const connectingLineColor = !isLast
    ? step.complete
      ? "brand.medium"
      : "border.medium"
    : "transparent"; // Last item getting no guiding line

  const iconBackgroundColor = step.complete ? "brand.medium" : "white";

  return (
    <Box w="full" display="flex" flexDirection="row">
      <Box
        w="30px"
        minW="30px"
        maxW="30px"
        ml="3"
        pl={-3}
        borderLeftWidth="2px"
        borderLeftColor={connectingLineColor}
      >
        <Box
          w={6}
          h={6}
          backgroundColor={iconBackgroundColor}
          borderRadius="full"
          borderWidth="2px"
          borderColor={iconOutlineColor}
          transform="translateX(-13px)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {step.complete && <Image src={TickIconSVG} width="16px" />}
        </Box>
      </Box>
      <Box>
        <Text size="lg" fontWeight="bold">
          {step.label}
        </Text>
        {step.description && (
          <Text mt={2} mb={4}>
            {step.description}
          </Text>
        )}
      </Box>
    </Box>
  );
};
const MilestoneListBlock: React.FC<Props> = ({ steps }) => {
  return (
    <Box w="full">
      {steps &&
        steps?.length > 0 &&
        steps.map((step: MilestoneStep, index: number) => (
          <MilestoneStepItem
            step={step}
            index={index}
            key={"MilestoneItem" + index}
            length={steps.length}
          />
        ))}
    </Box>
  );
};

export default MilestoneListBlock;
