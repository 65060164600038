import React from "react";

import { Box, Button } from "@chakra-ui/react";

import CloseButton from "./CloseButton";

interface Props {
  hostBankName: string;
  renderCloseConfirmationModal: boolean;
}

const ExitBlock: React.FC<Props> = ({
  hostBankName,
  renderCloseConfirmationModal,
}) => {
  {
    /* TODO: @taylor Remove interstitial from NavBar 
    https://github.com/column-tax/column/pull/1820/files/a7913208596e044361026199a30f6f51532c1a68#r1330764826*/
  }
  return (
    <CloseButton
      small
      renderCloseConfirmationModal={renderCloseConfirmationModal}
      component={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minW={{ base: "full", md: "calc(50% - 6px)" }}
        >
          <Button width="full" variant="primary" maxW={{ md: "300px" }}>
            Return to {hostBankName}
          </Button>
        </Box>
      }
    />
  );
};

export default ExitBlock;
